import React from "react"
import PropTypes from "prop-types"
import { NosotrosWrapper, NosotrosTitle, NosotrosFotoLocal } from "./style"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../../../components/Heading"
import Img from "gatsby-image"
import Text from "../../../components/Text"
import { Container, Row, Col } from "../../../components/UI/wrapper"

const NosotrosArea = ({ headingStyle, textStyle }) => {
  const data = useStaticQuery(graphql`
    query FotoLocalQuery {
      file(relativePath: { eq: "local-seggasa.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <NosotrosWrapper>
      <Container>
        <Row>
          <Col lg={8}>
            <NosotrosTitle>
              <Heading {...headingStyle} content="Nosotros" />
            </NosotrosTitle>
            <Text
              {...textStyle}
              content="Con más de 28 años de experiencia en el mercado nacional, somos una
            de las principales empresas importadoras y comercializadoras de
            llantas en el Perú, representantes de prestigiosas marcas entre las
            que destacan MICHELIN, BRIDGESTONE, FIRESTONE, GOOD YEAR, SOLIDEAL,
            BF GOODRICH y LIMA CAUCHO, productos de calidad que nos permite
            ofrecer variedad, seguridad, garantía y el mejor rendimiento."
            />
            <Text
              {...textStyle}
              content="
          Contamos con stock permanente, lo que nos permite atender sus pedidos
          a nivel nacional realizando un servicio personalizado con entregas en
          tiempo oportuno e inmediato."
            />
            <Text
              {...textStyle}
              content="
          Como parte de nuestro servicio post-venta a través de técnicos
          altamente calificados, brindamos asesoramiento y soporte técnico
          efectuando visitas programadas a fin de dar las recomendaciones
          necesarias para el uso correcto y mantenimiento de sus llantas
          optimizando el rendimiento y logrando reducir significativamente sus
          costos de producción lo cual estamos seguros contribuirá con el
          desarrollo y progreso mutuo."
            />
            <NosotrosTitle>
              <Heading {...headingStyle} content="Visión y Misión" />
            </NosotrosTitle>
            <Text
              {...textStyle}
              content="
          Orientar a nuestros clientes a la consecución del objetivo de lograr
          una satisfacción real y optimización de sus recursos vinculados al uso
          adecuado de los neumáticos para los diversos tipos de operación y
          escenarios donde desarrollen sus actividades y con esto contribuir al
          progreso de la movilidad de bienes y personas."
            />
          </Col>
          <Col lg={4}>
            <NosotrosFotoLocal>
              <Img
                fixed={data.file.childImageSharp.fixed}
                alt="Local Seggasa"
              />
            </NosotrosFotoLocal>
          </Col>
        </Row>
      </Container>
    </NosotrosWrapper>
  )
}

NosotrosArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
}

NosotrosArea.defaultProps = {
  headingStyle: {
    as: "h3",
    child: {
      color: "primary",
    },
  },
  textStyle: {
    mt: "30px",
  },
}

export default NosotrosArea
