import styled from "styled-components"
import { device } from "../../../theme"

export const NosotrosWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 35px;
  @media ${device.medium} {
    padding-top: 70px;
    padding-bottom: 15px;
  }
  @media ${device.small} {
    padding-top: 56px;
    padding-bottom: 25px;
  }
`

export const NosotrosTitle = styled.div`
  margin-bottom: 60px;
  @media ${device.medium} {
    margin-bottom: 50px;
  }
`
export const NosotrosFotoLocal = styled.div`
  margin-top: 0;
  @media (min-width: 991px) {
    flex: 0 0 42%;
    max-width: 42%;
    padding-right: 60px;
    margin: 0;
    &:before {
      width: calc(80% - 60px);
    }
  }

  margin-top: 45px;
  position: relative;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 575px) {
    margin-top: 35px;
  }

  img {
    border-radius: 3px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: #757575;
    bottom: 0;
    left: 10%;
    filter: blur(15px);
  }
`
