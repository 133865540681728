import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MarcasWrapper } from "./style"
import BoxImage from "../../../components/box-image/layout-two"
import SectionTitle from "../../../components/UI/section-title"
import { Container, Row, Col } from "../../../components/UI/wrapper"

const MarcasArea = () => {
  const data = useStaticQuery(graphql`
    query MarcasQuery {
      allPrismicMarca {
        edges {
          node {
            data {
              image {
                fluid(maxWidth: 260) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <MarcasWrapper>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle
              subtitle="OFRECEMOS LAS MEJORES MARCAS"
              title="Contamos con las mejores marcas del mercado que garantizan el cumplimiento de todos tus requerimientos."
            />
          </Col>
        </Row>
        <Row>
          {data.allPrismicMarca.edges.map((item, index) => (
            <Col lg={4} md={6} className="box-item" key={`box-image-${index}`}>
              <BoxImage imageSrc={item.node.data.image.fluid.src} />
            </Col>
          ))}
        </Row>
      </Container>
    </MarcasWrapper>
  )
}

export default MarcasArea
