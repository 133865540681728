import React from "react"
import SEO from "../components/seo"
import NosotrosArea from "../containers/nosotros/nosotros-area"
import MarcasArea from "../containers/nosotros/marcas-area"
import ResourcesArea from "../containers/home/resources-area"

const SeccionNosotros = () => (
  <>
    <SEO title="Segassa, nosotros" />
    <main className="site-wrapper-reveal">
      <NosotrosArea />
      <MarcasArea />
      <ResourcesArea />
    </main>
  </>
)

export default SeccionNosotros
